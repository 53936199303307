<template>
  <v-container fluid v-if="!isTab">
    <v-row dense v-show="!isTab">
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text" class="mx-auto">
            <v-toolbar-title>The AAU Point System</v-toolbar-title>
          </v-toolbar>
          <v-card-text :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
            <my-content></my-content>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <my-content v-else v-show="isTab" :isTab="isTab"></my-content>
</template>

<script>
import Content from '@/components/Points/AAUPointsContent'

export default {
  props: ['isTab'],
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  components: {
    'my-content': Content
  }
}
</script>

<style>

</style>
